import React from "react"
import Topic from "../../components/Topic"

const HealthAndMedical = () => (
  <Topic id="health_and_medical">
    <p>
      Canada has a good healthcare system that provides medical services free of
      charge for citizens, permanent residents as well as refugee claimants.
      Health care is a provincial responsibility and therefore specific health
      care coverage differs between provinces.
    </p>
    <p>
      Registering for health care also differs from province to province. You
      will want to find a local doctor who will be able to refer you to other
      specialized medical services. This section includes information about the
      different provincial health programs as well as instructions for applying
      for coverage.
    </p>
    <p>
      Mental and emotional well-being is an important part of staying healthy.
      You will also find information on how to get help if you are experiencing
      stress, anxiety, depression or other mental health challenges.
    </p>
  </Topic>
)

export default HealthAndMedical
